import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';

import TwoColCardSection from '../components/twoColCardSection';
import Layout from '../components/layout';

const tools = [
  {
    name: 'Dough Calculator',
    href: '/doughCalculator',
    imageName: 'scale',
    description:
      'Calculate ingredient weights based on hydration, salt, and starter percentages.'
  },
  {
    name: 'Starter DME Calculator',
    href: '/dmeCalculator',
    imageName: 'malt',
    description:
      'Calculate DME amount for yeast starters based on starter size and desired starter original gravity.'
  },
  {
    name: 'Coffee Ratio Calculator',
    href: '/coffeeCalculator',
    imageName: 'coffee',
    description:
      'Calculate coffee & water weights in grams based on a coffee:water ratio. Coffee is fermented so it kinda counts...'
  }
];

const Figure = styled.figure`
  margin: 0;
`;

const TitleText = styled.span`
  font-size: 1.5rem;
`;

const Card = styled.div`
  margin-bottom: 2rem;
  color: #717171;
`;

export default ({ data }) => {
  const toolComponents = tools.map(({ name, href, description, imageName }) => (
    <Card className="card" key={href}>
      <div className="card-image">
        <Link to={href}>
          <Figure className="image is-4by3">
            <Img fluid={data[imageName].childImageSharp.fluid} />
          </Figure>
        </Link>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <div className="title">
              <Link to={href}>
                <TitleText>{name}</TitleText>
              </Link>
            </div>
            <div className="subtitle">{description}</div>
          </div>
        </div>
      </div>
    </Card>
  ));

  return (
    <Layout>
      <Helmet>
        <title>Tools</title>
      </Helmet>
      <div>
        <h1>Tools</h1>
        <TwoColCardSection>{toolComponents}</TwoColCardSection>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    scale: file(relativePath: { eq: "tools/scale.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    malt: file(relativePath: { eq: "tools/malt.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    coffee: file(relativePath: { eq: "tools/coffee.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
